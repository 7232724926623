import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';

import {farmAPI} from 'api/farm.js';
import {DateUtil} from 'utils/date-util.js';
import {ErrorUtil} from 'utils/error-util.js';
import {ViewMode} from 'models/view-mode.js';
import {Farm} from 'models/farm.js';
import {sessionState} from 'session/session-state.js';

import {ReturnButton} from 'components/ReturnButton.js';
import {FarmInput} from 'components/forms/farm/FarmInput.js';
import {ReadOnlyText} from 'components/ReadOnlyText.js';
import {ErrorMessage} from 'components/ErrorMessage.js';
import {GlobalCss} from 'components/global-css.js';

const styles = {
  flexContainer: GlobalCss.flexContainer,
  leftJustifiedIitem: GlobalCss.leftJustifiedIitem,
  rightJustifiedIitem: GlobalCss.rightJustifiedIitem,
  textField: {
    width: 300
  }
};

class FarmEdit extends React.Component {
  constructor(props) {
    super(props);

    const params = props.match.params;
    this.farmId = params.id ? parseInt(params.id, 10) : null;
    this.viewMode = new ViewMode(params.action);

    this.title = `牧場 - ${this.viewMode.label}`;
    this.notification = `${this.viewMode.label}が完了しました`;
    this.buttonColor = this.viewMode.isDelete() ? 'secondary' : 'primary';

    this.state = {
      record: FarmEdit.createInitialRecord(),
      loaded: false,
      showProgress: false,
      showDialog: false,
      showConfirmDialog: false,
      submitDisabled: this.viewMode.isDelete()
    };
  }

  static get UPDATE_COLUMNS() {
    const result = [
      'farmName',
      'farmAddress',
      'farmPhone',
      'startDate',
      'farmKind',
      'farmTypeDairy',
      'farmTypeBreeding',
      'farmTypeFattening',
      'contractedPlan',
      'managementStyle',
      'identificationType',
      'cowNoPattern',
      'allowDuplicationCowNo',
      'cowNoMatchingPattern',
      'showHeatIndexFattening',
      'alertDysstasia',
      'fatteningStageBaseDate',
      'heatAlertTargetStates',
      'useMilking',
      'milkingDailyBoundaryAt',
      'alertLocation',
      'autoMovement',
      'autoMovementInterval',
      'useUClips',
      'useLegacyReportMenu',
      'calfManagement',
      'usageStatus',
      'dhiLinkage',
      'useAlertCalving',
      'useCalfManagement',
      'useSensorType',
      'detectDropoutEartagSensor',
      'eartagDropoutSolver',
      'useAlertEartagSensor'
    ];
    Farm.ACTIVITY_COLUMNS.forEach((e) => result.push(e.id));
    Farm.USER_DEFINED_OPTIONS.forEach((e) => result.push(e.id));

    return result;
  }

  static createInitialRecord() {
    const result = {
      farmName: '',
      farmAddress: '',
      farmPhone: '',
      startDate: null,
      farmKind: '肉用',
      farmTypeDairy: false,
      farmTypeBreeding: false,
      farmTypeFattening: true,
      contractedPlan: 'light',
      managementStyle: 'standard',
      identificationType: 'cow_no',
      allowDuplicationCowNo: false,
      cowNoPattern: 'number',
      cowNoMatchingPattern: 'complete',
      showHeatIndexFattening: false,
      alertDysstasia: false,
      fatteningStageBaseDate: 'birthday',
      heatAlertTargetStates: '',
      useMilking: false,
      milkingAailyBoundaryAt: '00:00',
      alertLocation: false,
      autoMovement: false,
      autoMovementInterval: 0,
      useUClips: false,
      useLegacyReportMenu: false,
      calfManagement: 'calved',
      usageStatus: 'active',
      dhiLinkage: 'none',
      useAlertCalving: false,
      certificateAiMenus: Farm.CERTIFICATE_AI_MENUS.map((menu) => {
        return {
          id: menu.id,
          label: menu.label,
          checked: menu.id === 'standard',
        };
      }),
      detectDropoutEartagSensor: false,
      useSensorType: 'use_neck_sensor',
      eartagDropoutSolver: 'skip',
      useAlertEartagSensor: false
    };
    Farm.ACTIVITY_COLUMNS.forEach((e) => result[e.id] = e.initial);
    Farm.USER_DEFINED_OPTIONS.forEach((e) => result[e.id] = '');

    return result;
  }

  static validate(input, farmNames) {
    const errors = [];

    if (!input) {
      errors.push('内容が変更されていません');
      return {valid: false, errors: errors};
    }

    if (!input.farmName) {
      errors.push('牧場名が入力されていません');
    }

    if (farmNames.includes(input.farmName)) {
      errors.push('既に登録済の牧場名です');
    }

    const selectedMenus = input.certificateAiMenus.filter((menu) => menu.checked);
    if (selectedMenus.length === 0) {
      errors.push('授精証明書メニューが選択されていません');
    }

    Farm.ACTIVITY_COLUMNS.forEach((e) => {
      if (!input[e.id]) {
        errors.push(`${e.label}が入力されていません`);
      }
    });

    return {valid: errors.length === 0, errors: errors};
  }

  componentDidMount() {
    if (this.viewMode.isCreate()) {
      this.setState({
        loaded: true
      });
      return;
    }

    this.show();
  }

  show() {
    this.setState({showProgress: true});

    return farmAPI.show(this.farmId).then((res) => {
      const record = Object.assign({}, res.data);
      record.startDate = DateUtil.toDate(record.startDate);

      const menuIds = res.data.certificateAiMenus.menuIds;
      record.certificateAiMenus = Farm.CERTIFICATE_AI_MENUS.map((menu) => {
        return {
          id: menu.id,
          label: menu.label,
          checked: menuIds.includes(menu.id)
        };
      });

      this.setState({
        record: record,
        loaded: true,
        showProgress: false
      });
    });
  }

  returnAction() {
    this.props.history.push('/farm');
  }

  syncState(values) {
    const input = this.state.input || {...this.state.record};
    const newInput = Object.assign(input, values);
    this.setState({input: newInput});
  }

  deleteConfirm(farmNameConfirmed) {
    if (farmNameConfirmed === this.state.record.farmName) {
      this.setState({submitDisabled: false});
    } else {
      this.setState({submitDisabled: true});
    }
    this.syncState({farmNameConfirmed: farmNameConfirmed});
  }

  save() {
    let farmNames = [];
    if (this.viewMode.isCreate()) {
      farmNames = sessionState.farms().map((f) => f.farmName());
    } else {
      farmNames = sessionState.farms()
        .filter((f) => f.id() !== this.farmId)
        .map((f) => f.farmName());
    }

    const result = FarmEdit.validate(this.state.input, farmNames);
    if (!result.valid) {
      this.setState({errorMessage: result.errors.join('\n')});
      return;
    } else {
      this.setState({errorMessage: ''});
    }

    let action;
    if (this.viewMode.isCreate()) {
      action = farmAPI.create(this.createParams());
    } else if (this.viewMode.isUpdate()) {
      action = farmAPI.update(this.farmId, this.createParams());
    } else if (this.viewMode.isDelete()) {
      action = farmAPI.delete(this.farmId, this.state.input.farmNameConfirmed);
    }

    this.setState({showProgress: true});

    action.then((res) => {
      return farmAPI.index().then((res) => {
        const farms = res.data.map((r) => new Farm(r));
        sessionState.store('farms', farms);

        this.setState({showDialog: true, showProgress: false});
        setTimeout(() => {
          this.setState({showDialog: false});

          const oldPlan = this.state.record.contractedPlan;
          const newPlan = this.state.input.contractedPlan;

          if (this.viewMode.mode === 'update' && oldPlan !== newPlan) {
            const farm = farms.find((f) => f.farmName() === this.state.input.farmName);

            sessionState.changeFarmId(farm.id());

            this.confirmText = `契約プランが${oldPlan}から${newPlan}に変更されました。牛舎画面へ遷移しますか？`;
            this.setState({showConfirmDialog: true});
          } else {
            this.returnAction();
          }
        }, 1000);
      });
    }).catch((error) => {
      this.setState({showProgress: false});
      const errorMessage = ErrorUtil.generateMessage(error);
      this.setState({errorMessage: errorMessage});
      console.error(errorMessage);
    });
  }

  moveToFarm() {
    this.setState({showConfirmDialog: false});
    this.returnAction();
  }

  moveToCowshed() {
    this.setState({showConfirmDialog: false});
    this.props.history.push('/cowshed');
  }

  createParams() {
    const input = this.state.input;
    const params = {};
    FarmEdit.UPDATE_COLUMNS.forEach((column) => {
      params[column] = input[column];
    });

    params.startDate = DateUtil.toMSec(params.startDate);

    const certificateAiMenuIds = input.certificateAiMenus.filter((menu) => menu.checked)
      .map((menu) => menu.id);
    params.certificateAiMenus = {menuIds: certificateAiMenuIds};

    return params;
  }

  render() {
    const classes = this.props.classes;

    return (
      <div style={{marginLeft: '20px'}}>
        <h2>{this.title}</h2>

        <ul className={classes.flexContainer}>
          <li>
            <ReturnButton returnAction={() => this.returnAction()} />
          </li>

          <li style={{marginLeft: '500px'}}>
            <Button
              variant="contained"
              color={this.buttonColor}
              onClick={() => this.save()}
              disabled={this.state.showProgress || this.state.submitDisabled}>
              {this.viewMode.label}
            </Button>
          </li>
        </ul>

        <div style={{marginLeft: '40px'}}>
          <ErrorMessage errorMessage={this.state.errorMessage} />
        </div>

        <div style={{width: 300, marginBottom: 10, marginLeft: 200}}>
          {this.state.showProgress ? <LinearProgress /> : null}
        </div>

        <Dialog
          open={this.state.showDialog}
          onClose={() => this.setState({showDialog: false})}
          TransitionComponent={Slide}
          aria-labelledby="notification-dialog"
        >
          <DialogTitle id="notification-dialog">{this.notification}</DialogTitle>
        </Dialog>

        <Dialog
          open={this.state.showConfirmDialog}
          onClose={() => this.setState({showConfirmDialog: false})}
          TransitionComponent={Slide}
          aria-labelledby="confirm-dialog"
        >
          <DialogTitle id="confirm-dialog">確認</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.confirmText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.moveToFarm()} color="primary">
              いいえ
            </Button>
            <Button onClick={() => this.moveToCowshed()} color="primary">
              はい
            </Button>
          </DialogActions>
        </Dialog>

        <DetailForm
          loaded={this.state.loaded}
          record={this.state.record}
          state={this.state}
          viewMode={this.viewMode}
          syncState={this.syncState.bind(this)}
          deleteConfirm={this.deleteConfirm.bind(this)}
        />
      </div>
    );
  }
}

FarmEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const forExport = withRouter(withStyles(styles)(FarmEdit));
export {forExport as FarmEdit};

const DetailForm = (props) => {
  if (props.loaded) {
    if (props.viewMode.isDelete()) {
      return (
        <DeleteForm
          record={props.record}
          state={props.state}
          deleteConfirm={props.deleteConfirm}
        />
      );
    } else {
      return (
        <FarmInput
          record={props.record}
          syncState={props.syncState}
        />
      );
    }

  } else {
    return null;
  }
};

DetailForm.propTypes = {
  record: PropTypes.object.isRequired
};

const DeleteForm = (props) => {
  const record = props.record;
  const state = props.state;
  const deleteConfirm = props.deleteConfirm;

  return (
    <div style={{marginLeft: '100px'}}>
      <div style={{marginBottom: 50}}>
        <ReadOnlyText
          label="牧場名"
          value={record.farmName}
        />
      </div>

      <div>
        <TextField
          type="text" margin="none"
          label="削除する牧場名の確認"
          value={state.farmNameConfirmed}
          onChange={(e) => deleteConfirm(e.target.value)}
          placeholder={record.farmName}
          style={{widt: 300}}
        />
      </div>
    </div>
  );
};

DeleteForm.propTypes = {
  record: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  deleteConfirm: PropTypes.func.isRequired,
};
